import { LogLevel } from '@azure/msal-browser';
import { gSubdomain, getRelyingParty } from './config';

// ref: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md

let g_msalConfig = undefined;

const rpInfo = await getRelyingParty();

if (rpInfo) {
  if (rpInfo?.data?.aadTenantId && rpInfo?.data?.aadClientId) {
    console.info(`msalConfig(): tenantId: ${rpInfo.data.aadTenantId}, clientId: ${rpInfo.data.aadClientId}`);
    g_msalConfig = 
      { auth:
        { clientId                  : rpInfo?.data?.aadClientId
        , authority                 : `https://login.microsoftonline.com/${rpInfo?.data?.aadTenantId}`
        , postLogoutRedirectUri     : `${window.location.origin}/#${gSubdomain}`
        , navigateToLoginRequestUrl : false
        }
      , cache:
        { cacheLocation             : 'localStorage' // required popup and iframe windows from deep links
        , storeAuthStateInCookie    : false
        }
      , system:
        { loggerOptions:
          { loggerCallback: (level, message, containsPii) => {
              if (containsPii) return;
              switch (level) 
              { case LogLevel.Error:
                  console.error(`msalConfig.loggerCallback() ${message}`);
                  return;
                case LogLevel.Warning:
                  console.warn (`msalConfig.loggerCallback() ${message}`);
                  return;
                case LogLevel.Info:
                case LogLevel.Verbose:
                  return;
                default:
                  console.log  (`msalConfig.loggerCallback() ${message}`);
                  return;
              }
            }
          }
        }
      };
  } else {
    console.info(`msalConfig(): not configured - using IDgo local accounts`);    
  }
} else {
  console.error(`msalConfig(): subdomain not found`);
};
  
export const msalConfig = g_msalConfig;