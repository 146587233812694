// config.js

import axios from 'axios';
import { revision } from './revision';

/***************************************************************************
  The current url convention for IDgo Agent and Admin applications is:
    - agent.<env>.cozera.io/#<subdomain>

    Where 'subdomain' is a string such as 'advantiscu' for Advantis Credit Union
    And   'env' defines the environment. Current values are:
        'DEV' for development testing
        'UAT' for user acceptance testing
        'PROD' for Production

    Examples:
    - https://agent.prod.cozera.io/#advantiscu for Advantis IDgo Agent in Production
    - https://admin.uat.cozera.io/#rivermarkcu for Rivermark IDgo Admin in UAT
    - https://admin.dev.cozera.io/#rivermarkcu for Rivermark IDgo Admin in DEV
    Vanity URLs with dedicated DNS names (legacy):
    - https://advantiscu-admin.cozera.io/
***************************************************************************/

var g_env = 'PROD';
var g_subdomain='';
var href = window.location.href;

// use cases for testing different contexts is performed by running on different ports
if      (href.startsWith('http://localhost:3000')) href = 'https://agent.dev.cozera.io/#demo1';
else if (href.startsWith('http://localhost:3001')) href = 'https://agent.localdev.cozera.io/#demo1';
else if (href.startsWith('http://localhost:3002')) href = 'https://agent.dev.cozera.io/#demo2';
else if (href.startsWith('http://localhost:3003')) href = 'https://agent.dev.cozera.io/#demo3';
else if (href.startsWith('http://localhost:3004')) href = 'https://advantiscu-agent.cozera.io/';   // for testing legacy bespoke DNS names

// For produciton legacy vanity URLs we need to assume we are in PROD because we cannot easily derive the environment from the URL
// There are no vanity URLs for UAT and DEV, so we know the environment can be derived by the multi-tenant URL scheme.
// Although we don't need to do this if the URL was overridden above, because we know we are in a localdev environment.
if (href!==window.location.href) g_env = 'DEV';

const fragment_convention_regx = /(\.cozera.io\/){1}.*(#[a-z\d]){1}/;
const fragment_match = fragment_convention_regx.exec(href);

if (fragment_match) {
  // New multi-tenant URL convention
  const env_regx  = /\.([a-z\d]+)\.cozera.io/g;
  const env_match = env_regx.exec(href);
  if (env_match) { g_env = env_match[1].toUpperCase(); }
  const sub_regx  = /#([a-z\d]+)/g;
  const sub_match = sub_regx.exec(href);
  if (sub_match) { g_subdomain = sub_match[1]; }
} else {
  // Check the only remaining legacy URL convention
  if (href==='https://unituscu-agent.cozera.io/') g_subdomain='unituscu';
}

// values are accessed by Relying Party subdomain prefix and Environment
const g_envCfg =
{ 'LOCALDEV'     :
  { IDENTITY_SERVICE      : 'http://localhost:3000'
  , PUBNUB_SUBSCRIBE_KEY  : 'sub-c-30fdb813-0370-4b27-80b6-6ea4ebcd3096'
  , USER_AUTH_SERVICE     : 'http://localhost:5000'
  }
  ,'DEV'     :
  { IDENTITY_SERVICE      : 'https://dev.cozera-ops.net/service-identity'
  , PUBNUB_SUBSCRIBE_KEY  : 'sub-c-30fdb813-0370-4b27-80b6-6ea4ebcd3096'
  , USER_AUTH_SERVICE     : 'https://dev.cozera-ops.net/web-apps-server'
  }
, 'UAT'     :
  { IDENTITY_SERVICE      : 'https://uat.cozera-ops.net/service-identity'
  , PUBNUB_SUBSCRIBE_KEY  : 'sub-c-a77314d6-5076-11eb-9d3f-7e8713e36938'
  , USER_AUTH_SERVICE     : 'https://uat.cozera-ops.net/web-apps-server'
  }
, 'PROD'    :
  { IDENTITY_SERVICE      : 'https://prod.cozera-ops.net/service-identity'
  , PUBNUB_SUBSCRIBE_KEY  : 'sub-c-76dbf714-9ab1-11ea-8d30-d29256d12d3d'
  , USER_AUTH_SERVICE     : 'https://prod.cozera-ops.net/web-apps-server'
  }
};

export const gSubdomain = g_subdomain;

export const ctxValue = (key) => {
  switch (key) {
    case 'ENV':       return g_env;
    case 'SUBDOMAIN': return g_subdomain;
    default:
      if (!(g_env in g_envCfg)) {
        console.error('Error: env <' + g_env + '> not defined in relying_party_env_cfg')
        return;
      }
      if (!(key in g_envCfg[g_env])) {
        console.error('Error: key <' + key + '> not defined in relying_party_env_cfg['+g_env+']')
        return;
      }
      return g_envCfg[g_env][key];
  }
};

// fetched from DB on first request
let g_RelyingParty;

export const getRelyingParty = async () => {
  if (!g_RelyingParty) {
    g_RelyingParty = await _getRelyingParty(g_subdomain);
  }
  if (!g_RelyingParty) {
    console.error(`getRelyingParty() Error: No RP information for subdomain <${g_subdomain}>`)
    return;
  }
  return g_RelyingParty;
};

// _internal function
const _getRelyingParty = async (subdomain) => {
  const url = `${g_envCfg[g_env]['IDENTITY_SERVICE']}/relyingParty/v1/${subdomain}`;
  const rpInfo = await axios.get(url, { headers: {'x-client':`IDgo Agent ${revision}`} })
    .then(result => { return {status:200, data:result.data}})
    .catch(err => {
      if (err?.status===404) {
        return {status:404};
      }
      console.error(`_getRelyingParty() exception: ${err}`);
      return {status:500, message:err?.message};
    });
  return rpInfo;
};