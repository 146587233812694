import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

// Components
import InputValidation from '../common/InputValidation';
import EmailAddressInput from '../common/EmailAddressInput';
import Alert from '../layout/Alert';

// Utils
import { isValidEmail, onClickFocus } from '../../utils/helpers';
import { setValidEmail, setInvalidEmail } from '../../utils/toggleValidationFeedback';
import { ctxValue } from '../../utils/config';
import { changeEmail, logout } from '../../utils/services';
import { getUser } from '../../utils/session';

const ChangeEmail = () => {
  const history = useHistory();
  const [currentEmail, setCurrentEmail] = useState();
  const [newEmail, setNewEmail] = useState();
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    const user = getUser();
    setCurrentEmail(user?.email);
  }, []);

  const onChange = (e) => {
    setNewEmail(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const validEmail = isValidEmail(newEmail);

    if (validEmail) setValidEmail(`email-input-group`);
    else setInvalidEmail(`email-input-group`);

    if (!validEmail) return;

    const result = await changeEmail(newEmail);

    if (result?.status!==200) {
      setAlerts([...alerts, {heading: 'Hang On!', msg:result.message, type:'danger'}]);
      setTimeout(() => setAlerts([]), 8000);
      return;
    }

    logout();
    history.push({ pathname: '/change-email-success', state: {email: newEmail}, hash: ctxValue('SUBDOMAIN') });
  };

  return (
    <>
      <InputValidation formName='changeEmailForm' />
      <div className='row'>
        <div className='text-center mx-auto'>
          <Alert alerts={alerts}/>
          <p style={{ fontSize: '16pt', marginBottom: '20px' }}>
            Change Your Email Address
          </p>
          <div className='text-left'>
            <small className='text-muted'>Current email address</small>
            <p>{currentEmail}</p>
          </div>
          {/* the 1st 4 form attributes were added per 2024 WEBSEC pentest recommendations */}
          <form
            spellCheck='false'
            autoComplete='off'
            autoCorrect='off'
            autoCapitalize='off'
            name='changeEmailForm'
            onSubmit={(e) => onSubmit(e)}
            method='post'
            noValidate
          >
            <div id='email-input-group' style={{ marginBottom: '25px' }}>
              <EmailAddressInput
                id='email'
                name='email'
                value={newEmail}
                onChange={onChange}
                onClick={(e) => onClickFocus(e)}
              />
            </div>
            <div style={{ marginBottom: '40px' }}>
              <Link
                className='btn btn-outline-primary btn-sm float-left'
                to={'/lookup#'+ctxValue('SUBDOMAIN')}
              >
                Cancel
              </Link>
              <input
                type='submit'
                value='Save'
                className='btn btn-primary btn-sm float-right'
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangeEmail;